import sccAboutImg from "../assets/images/sccAbout.jpg";
import styles from "../assets/stylesheets/About.module.css";

export default function About() {
  return (
    <>
      <img className={styles.scc__about__img} src={sccAboutImg} alt=""></img>
      <div className={styles.scc__about__container}>
        <div className={styles.scc__about__paragraph}>
          <p className={styles.scc__paragraph__heading}>FOUNDED MARCH 9, 1924</p>
          <p>
            As a mission to the Canonsburg residents and began its meetings in
            the Nickelodeon, directly across the street from its present
            location. Services were conducted by Rev. and Mrs. Charles Pridgeon,
            the former pastor of the First Presbyterian Church of Canonsburg. In
            1901, Rev. Pridgeon left that position and founded the Pittsburgh
            Bible Institute and later the Evangelization Society, which now
            operates under the name Heights International Ministry,
            headquartered in Gibsonia, PA.
          </p>
        </div>
        <div className={styles.scc__about__paragraph}>
          <p className={styles.scc__paragraph__heading}>WHO WE ARE</p>
          <p>
            In a day and time when everyone has grown accustomed to “instant”
            everything, it is rare for individuals to simply “wait” upon the
            Lord. Yet, South Canonsburg Church seeks to be a community of
            believers that wait upon the directive of the Lord through the Holy
            Spirit. When we claim to be a “Full Gospel” church, we are making a
            declaration that we both believe the entire or full Word of God -
            the Bible - and that we seek to give full freedom to the Lord’s
            working in our individual lives and corporate worship through the
            praise of the Holy Spirit. We recognize that we do not hold a
            monopoly on truth as to the format of worship and to the expression
            of faith, but we do strive to maintain fervency of conviction to
            Scriptural standards and singleness of focus to the establishment of
            the Kingdom purposes of God. In theological persuasion, we are
            evangelical, holding the Bible to be the inspired word of God and to
            be our standard for doctrine, for pointing out errors, for
            correction and for training people for a life that has God’s
            approval (2 Timothy 3:16). In worship expression, we are
            progressive, seeking to allow individuals the freedom of form while
            maintaining an orderliness characterized by Godliness. In family
            life, we seek to be a loving, forgiving, nurturing and caring
            community of faith. In outreach, we are universal, understanding
            that the world is our parish and responding to our Lord’s mandate to
            go into all the world, to evangelize and disciple.
          </p>
        </div>
        <div className={styles.scc__about__paragraph}>
          <p className={styles.scc__paragraph__heading}>OUR STORY</p>
          <p>
            In its beginning as a mission, the Pridgeons would come and hold
            meetings in the frame Nickelodeon building with its sawdust floor,
            open burner gas heaters and a pump organ, which Mrs. Pridgeon
            played. People of the town would brave the coldness of the building
            to come and hear the soul-saving gospel from these dedicated people.
            In 1913, the building was altered so that it could be better used as
            a church and thus the ground work was being laid for a permanent
            church. The work really forged ahead with Laura Silbaugh in 1920. A
            mighty spiritual revival ensued through the power of the Holy Spirit
            and the crowds grew until they packed out the building. God worked
            in the hearts of the sinners, especially the alcoholics of the town.
            Miss Silbaugh gave herself to the ministry of reaching the community
            and was often seen walking the hills of Canonsburg inviting those
            who needed God to the meetings. Soon after this movement of God, the
            leaders of the meetings started a building fund and with the
            proceeds they purchased the lot across the street from where the
            present church now stands. Because of the low laying area of the
            property, dirt was hauled in to raise the property to street level.
            Much of the building was erected by the individuals who attended the
            church. The church was finally completed and was dedicated on March
            9, 1924. Rev. Ralph T. Kemper and Miss Silbaugh were married in
            August of 1923. The two of them labored together in the work of the
            church until her death in 1925. Several years later, Rev. Kemper
            married Evelyn Poole, a home missionary to the mining towns around
            the area. The two of them co-pastored the South Canonsburg Church
            for a number of years. God began to bless the church with many
            souls. In one revival service that Rev. Kemper was preaching, there
            were over 100 souls saved. Attendance was running around 250-350 in
            each service. The balcony was often used and the doors were opened
            from the prayer chapel into the sanctuary for additional seating.
            The church began to have a large outreach with children and youth
            choirs. Rev. Kemper directed these choirs along with the
            accompanying musician, Mrs. Beatrice Kreer. As their ministry
            expanded, the Whosoever Chorus Ministry, as it became known, sang at
            Pittsburgh radio stations, hospitals and jails. They would travel on
            their own bus to sing at many revival meetings. Rev. and Mrs. Kemper
            left the church in 1960 to pastor a church in Pittsburgh. Rev. and
            Mrs. John Brooks, former missionaries to the Belgian Congo, came to
            pastor the church. The next five years brought many changes in the
            church. The Brooks family left in 1965 to return to Africa. After
            graduating from Asbury college in August of 1965, Rev. David Vogel
            and his family came to the church. Rev. Vogel's wife, Carol, is the
            daughter of Rev. and Mrs. Kemper. Joy Chapel, with their pastor,
            Rev. Jeanette Simpson, merged with South Canonsburg Church on June
            1, 1970. In 1979, remodeling began on the church building in order
            to make Sunday School rooms and a fellowship hall in the basement.
            While construction was underway, the church structure collapsed and
            consequently a new facility was erected. We have been enjoying this
            church building since.
          </p>
        </div>
      </div>
    </>
  );
}

import styles from "../assets/stylesheets/PoweredUp.module.css";

export default function PoweredUp() {
  return (
    <div className={styles.scc__poweredup__container}>
      <iframe
        className={styles.scc__poweredup__form}
        title="poweredUp"
        src="https://docs.google.com/forms/d/e/1FAIpQLScjngoBvndf-xik9XyF61CKhU7kssECYgkhRCRxLVApUtm6Xw/viewform?embedded=true"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
        Loading…
      </iframe>
    </div>
  );
}

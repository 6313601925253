import meetOurPastors from "../assets/images/meetOurPastors.jpeg";
import styles from "../assets/stylesheets/Pastors.module.css";

export default function Pastors() {
  return (
    <div className={styles.scc__pastors__container}>
      <div className={styles.scc__pastors__img__container}> 
        <img
          className={styles.scc__pastors__img}
          src={meetOurPastors}
          alt=""
        ></img>
      </div>
      <div className={styles.scc__pastors__text__container}>
        <p className={styles.scc__pastors__text__header}>
          WELCOME FROM THE PASTORS
        </p>
        <p>
          It’s our great pleasure to welcome you. We invite you come visit us
          during our time of celebration and worship to the <strong>ONE</strong>{" "}
          Who deserves that and so much and more! Whether you are a{" "}
          <em>“first time”</em> guest or a <em>“part”</em> of our growing
          extended church family, we want you to know that{" "}
          <strong>you are so special</strong>, both to Him and to us! We are
          here to <em>love</em>, <em>nurture</em> and <em>serve</em> the Body of
          Christ as we endeavor to grow toward becoming more like Him.{" "}
          <strong>Every one of us has a purpose in this life</strong> and you
          have been chosen by the Father. We want to see you fulfill those
          things that God has for you!
        </p>
        <p className={styles.scc__pastors__text__header}>ABOUT OUR PASTORS</p>
        <p>
          We are Pastors Steve and Doreen Parkhill. We have been blessed to
          serve at SCC since the fall of 1995. We raised our family here at SCC,
          including their training at Rainbowland Preschool and have built a
          community together that was Christ-centered and dependent upon the
          Holy Spirit. <br />
          <br />
          This is our heart: We desire to grow in the Lord and to provide an
          environment that meets the needs of the whole family. Worship is at
          the heart of our expression unto the Lord and the Bible is the
          foundation of our own home and that of the church. Practical Biblical
          teaching and Spirit-filled anointing make for a church that is
          relevant and power-filled. <br />
          <br />
          We love Jesus at SCC and want to help others love and serve Him too.
          If you are looking for a loving church family with genuine
          relationship with others, please check us out. Every service is a
          little different, based upon the directive of the Holy Spirit, so it
          may take more than just one visit to fully get to know who we are. We
          want you to feel at home and we want to get to know you. <br />
          <br />
          We hope that you will prayerfully consider visiting us. If you want to
          discover what it is to have a relationship with God, we can show you
          how or if you are looking for a church family that helps you to grow
          in the Word of God you will. <br />
          <br />
          <em>
            <strong>We are family . . . and you belong!</strong>
          </em>{" "}
          <br />
          <br />
          Blessings, <br />
          <br />
          <em>Pastors Steve and Doreen</em>
        </p>
      </div>
    </div>
  );
}

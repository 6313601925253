import styles from "../assets/stylesheets/Error.module.css";

export default function Error() {
  return (
    <div className={styles.scc__error__container}>
     <p className={styles.scc__error__fourofour}>
        404 Error
      </p>
      <p className={styles.scc__error__header}>
        The Page You are Looking For No Longer Exists
      </p>
      <a href="/" className={styles.scc__error__link}>
        Return Home
      </a>
    </div>
  );
}

import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../assets/stylesheets/NewPersonForm.module.css";

export default function NewPersonForm() {
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({
    fullName: "",
    email: "",
    phone: "",
    addressOne: "",
    addressTwo: "",
    city: "",
    state: "",
    zip: "",
    inquiry: "",
    ministries: "Choose Ministry",
  });

  function handleChange(event) {
    event.persist();

    setInputs((prevValue) => {
      return { ...prevValue, [event.target.id]: event.target.value };
    });
  }

  function handleSubmit(event) {
    event.preventDefault();

    axios({
      method: "POST",
      url: "https://app.formbold.com/s/oYnbv",
      data: {
        ...inputs,
      },
    })
      .then(() => {
        navigate("/thank-you");
      })
      .catch((r) => {
        console.log(r);
      });
  }

  return (
    <>
      <p className={styles.scc__new__header}>LET'S CONNECT</p>
      <div className={styles.scc__form__container}>
        <form onSubmit={handleSubmit} className={styles.scc__form}>
          <label htmlFor="fullName">Name:</label>
          <input
            onChange={handleChange}
            value={inputs.fullName}
            id="fullName"
            type="text"
            name="fullName"
            required
          />
          <label htmlFor="email">Email:</label>
          <input
            onChange={handleChange}
            value={inputs.email}
            id="email"
            type="email"
            name="email"
            required
          />
          <label htmlFor="phone">Phone Number:</label>
          <input
            onChange={handleChange}
            value={inputs.phone}
            id="phone"
            type="text"
            name="phone"
            required
          />
          <label htmlFor="addressOne">Address 1:</label>
          <input
            onChange={handleChange}
            value={inputs.addressOne}
            id="addressOne"
            type="text"
            name="addressOne"
            required
          />
          <label htmlFor="addressTwo">Address 2:</label>
          <input
            onChange={handleChange}
            value={inputs.addressTwo}
            id="addressTwo"
            type="text"
            name="addressTwo"
          />
          <label htmlFor="city">City:</label>
          <input
            onChange={handleChange}
            value={inputs.city}
            id="city"
            type="text"
            name="city"
            required
          />
          <label htmlFor="state">State:</label>
          <input
            onChange={handleChange}
            value={inputs.state}
            id="state"
            type="text"
            name="state"
            required
          />
          <label htmlFor="zip">Zip Code:</label>
          <input
            onChange={handleChange}
            value={inputs.zip}
            id="zip"
            type="text"
            name="zip"
            required
          />
          <label htmlFor="inquiry">How did you find out about SCC?:</label>
          <textarea
            onChange={handleChange}
            value={inputs.inquiry}
            id="inquiry"
            type="text"
            name="inquiry"
          />
          <label className={styles.scc__form__ministries} htmlFor="inquiry">
            What ministry would you like more information about?:
          </label>
          <select
            onChange={handleChange}
            value={inputs.ministries}
            id="ministries"
            name="ministries"
          >
            <option value="default">Choose a Ministry</option>
            <option value="rainbowland">Rainbowland Preschool</option>
            <option value="sccKidz">SCC Kidz</option>
            <option value="sccTeens">SCC Teens</option>
            <option value="familyLife">Family Life</option>
            <option value="gifts">Gifts of Joy</option>
            <option value="baskets">Baskets of Joy</option>
          </select>
          <div className={styles.scc__form__button__container}>
            <button type="submit" className={styles.scc__form__button}>
              SUBMIT
            </button>
            <a href="/" className={styles.scc__form__button}>
              CANCEL
            </a>
          </div>
        </form>
      </div>
    </>
  );
}

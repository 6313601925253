import styles from "../assets/stylesheets/VbsSignup.module.css";

export default function VbsSignup() {
  return (
    <div className={styles.scc__vbs__container}>
      <iframe
        className={styles.scc__vbs__form}
        src="https://docs.google.com/forms/d/e/1FAIpQLSfBbReEXSYRvInUYZUHwonIQ_07vimWhd-AsaLOHQ5r8Hztcg/viewform?embedded=true"
        title="rockyRailway"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
        Loading…
      </iframe>
    </div>
  );
}

import sccThankYou from "../assets/images/sccThankYou.jpg";
import styles from "../assets/stylesheets/ThankYou.module.css";

export default function ThankYou() {
  return (
    <>
      <img className={styles.scc__thankyou__img} src={sccThankYou} alt=""></img>
      <div className={styles.scc__thankyou__container}>
        <p>
          THANK YOU SO MUCH FOR CONNECTING WITH US. WE LOOK FORWARD TO GETTING
          TO KNOW YOU.
        </p>
        <p>WE'RE FAMILY, YOU BELONG!</p>
      </div>
    </>
  );
}

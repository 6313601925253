import rainbowlandPreschool from "../assets/images/rainbowlandPreschool.jpg";
import sccKidz from "../assets/images/sccKidz.jpg";
import poweredUp from "../assets/images/poweredUp.png";
import sccTeens from "../assets/images/sccTeens.jpg";
import sccFamilyLife from "../assets/images/sccFamilyLife.jpg";
import giftsOfJoy from "../assets/images/giftsOfJoy.jpg";
import basketsOfJoy from "../assets/images/basketsOfJoy.jpg";
import styles from "../assets/stylesheets/Connect.module.css";

export default function Connect() {
  return (
    <>
      <p className={styles.scc__connect__header}>MINISTRIES</p>
      <div className={styles.scc__connect__container}>
        <div className={styles.scc__content__container}>
          <a
            href="http://www.rainbowlandpreschool.com/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt=""
              className={styles.scc__connect__img}
              src={rainbowlandPreschool}
            ></img>
          </a>
          <div className={styles.scc__connect__text}>
            <p>
              <strong>RAINBOWLAND PRESCHOOL</strong>
            </p>
            <p>
              Rainbowland Preschool is a non-profit program sponsored by South
              Canonsburg church. It was established in September 1988 as an
              outreach to the community and to be a completely supervised
              program in an atmosphere of LOVE! It is to a large extent through
              play that young children develop. It gives them a chance to spend
              time in a physical environment which is scaled and planned for
              them. Our goal is to focus on creating a safe, nurturing
              environment that will stimulate cognitive learning and language
              development, increase social and problem solving skills,
              strengthen hand eye coordination and the development of
              small/large motor skills. Encouragement will be given to help
              achieve learning goals by building self-esteem and creating
              awareness of the environment around them.
            </p>
          </div>
        </div>
        <div className={styles.scc__content__container}>
          <img alt="" className={styles.scc__connect__img} src={sccKidz}></img>
          <div className={styles.scc__connect__text}>
            <p>
              <strong>SCC KIDZ</strong>
            </p>
            <p>
              SCC Kidz is a vibrant and engaging ministry at SCC, dedicated to
              providing a nurturing and transformative experience for children.
              Our mission is to create an inclusive environment where kids can
              grow in their relationship with God, discover the truth of His
              Word, and develop a lifelong love for Jesus. Through
              age-appropriate teachings, interactive activities, and intentional
              discipleship, SCC Kidz aims to equip children with a strong
              foundation in faith, character, and service. We believe that every
              child is uniquely created and valued by God, and we are committed
              to partnering with families to help children thrive spiritually,
              emotionally, and socially. Join SCC Kidz as we journey together,
              igniting a passion for Christ and empowering the next generation
              to impact the world with God's love.
            </p>
          </div>
        </div>
        <div className={styles.scc__content__container}>
          <a href="/connect/powered-up">
            <img
              alt=""
              className={styles.scc__connect__img}
              src={poweredUp}
            ></img>
          </a>
          <div className={styles.scc__connect__text}>
            <p>
              <strong>POWERED-UP</strong>
            </p>
            <p>
              Powered-Up Kids Ministries at SCC is a dynamic and captivating
              program designed to deliver a nurturing and transformative
              experience for children. Our primary goal is to foster an
              inclusive atmosphere where children can cultivate their
              relationship with God, uncover the truths within His Word, and
              nurture a lifelong devotion to Jesus. Through tailored teachings,
              interactive games, melodious music, scrumptious food, and
              deliberate mentorship, Powered Up Kids Ministries seeks to equip
              children with a robust foundation in faith, character, and
              service. We firmly believe that every child is a unique and
              cherished creation of God, and our unwavering commitment is to
              collaborate with families to support children in thriving
              spiritually, emotionally, and socially, igniting a deep passion
              for Christ and empowering the next generation to spread God's love
              and make a lasting impact on the world.
            </p>
          </div>
        </div>
        <div className={styles.scc__content__container}>
          <img alt="" className={styles.scc__connect__img} src={sccTeens}></img>
          <div className={styles.scc__connect__text}>
            <p>
              <strong>SCC TEENS</strong>
            </p>
            <p>
              SCC Teens is an empowering and supportive ministry at SCC,
              designed specifically for teenagers. Our vision is to create a
              dynamic and inclusive space where teens can thrive spiritually,
              grow in their relationship with God, and discover their unique
              purpose. Through relevant teachings, authentic discussions, and
              engaging activities, SCC Teens aims to equip teenagers with a
              solid foundation of faith, guiding them to navigate the challenges
              of adolescence and make wise choices. We foster an environment of
              belonging and acceptance, encouraging teens to build strong
              friendships, express their individuality, and develop a deep
              connection with Jesus. SCC Teens is a place where young people can
              be encouraged, inspired, and equipped to impact their generation
              and make a difference in the world around them. Join us at SCC
              Teens and embark on a journey of transformation, discovering your
              identity in Christ and unleashing your God-given potential.
            </p>
          </div>
        </div>
        <div className={styles.scc__content__container}>
          <img
            alt=""
            className={styles.scc__connect__img}
            src={sccFamilyLife}
          ></img>
          <div className={styles.scc__connect__text}>
            <p>
              <strong>SCC FAMILY LIFE</strong>
            </p>
            <p>
              "SCC Family Life" is an enriching Sunday School ministry at SCC,
              dedicated to fostering spiritual growth and deepening the
              understanding of God's Word within our church community. With a
              focus on family, we provide a nurturing environment where
              individuals of all ages can come together to study and apply the
              teachings of the Bible. SCC Family Life offers engaging Bible
              study sessions, thought-provoking discussions, and practical
              instruction that encourages personal and communal growth in faith.
              Our passionate and dedicated teachers guide participants through
              relevant topics and explore how biblical principles can be applied
              to everyday life. Whether you are a child, a teenager, a parent,
              or a grandparent, SCC Family Life welcomes you to join us on this
              journey of discovering God's truth, building strong foundations,
              and cultivating a vibrant spiritual life within the context of
              family and community.
            </p>
          </div>
        </div>
        <div className={styles.scc__content__container}>
          <img
            alt=""
            className={styles.scc__connect__img}
            src={giftsOfJoy}
          ></img>
          <div className={styles.scc__connect__text}>
            <p>
              <strong>GIFTS OF JOY</strong>
            </p>
            <p>
              "Gifts of Joy" is a heartwarming ministry at SCC, dedicated to
              spreading love and cheer to families in need within the greater
              community of Canonsburg, PA during the Christmas season. With a
              deep commitment to bringing hope and happiness, Gifts of Joy aims
              to provide Christmas gifts to families facing challenging
              circumstances. Through the generous support of our church members
              and community partners, we come together to gather, wrap, and
              distribute these gifts, ensuring that every family experiences the
              joy and wonder of the holiday season. Gifts of Joy not only
              brightens the lives of children but also extends a message of
              compassion, support, and inclusion to their families. This
              ministry embodies the spirit of giving, unity, and the true
              meaning of Christmas, reminding families that they are cared for,
              valued, and loved. Join us in this transformative mission of
              bringing smiles and spreading the true spirit of Christmas through
              the Gifts of Joy.
            </p>
          </div>
        </div>
        <div className={styles.scc__content__container}>
          <img
            alt=""
            className={styles.scc__connect__img}
            src={basketsOfJoy}
          ></img>
          <div className={styles.scc__connect__text}>
            <p>
              <strong>BASKETS OF JOY</strong>
            </p>
            <p>
              "Baskets of Joy" is a heartfelt ministry at SCC, dedicated to
              bringing hope and joy to families in need within the greater
              community of Canonsburg, PA during the Easter season. With a heart
              for compassion and a desire to make a difference, Baskets of Joy
              aims to provide Easter baskets filled with love, care, and festive
              treats to families facing challenging circumstances. Through the
              generous support of our church members and community partners, we
              come together to assemble and distribute these baskets, not only
              bringing smiles to the faces of children but also offering a
              tangible expression of love and support to their families. Baskets
              of Joy embodies the spirit of unity, compassion, and the true
              meaning of Easter, spreading joy and reminding families that they
              are seen, valued, and loved. Join us in this mission of making a
              positive impact and spreading hope, one basket at a time.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import styles from "../assets/stylesheets/Vbs.module.css";
import logo from "../assets/images/rocky-railway.png";
import { useNavigate } from "react-router-dom";

export default function Vbs() {
  const navigate = useNavigate();

  const calculateTimeLeft = () => {
    const difference = +new Date("2024-06-17") - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000); // Update every second

    return () => clearInterval(timer);
  }, []);

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      // If the interval value is zero, explicitly render it as "0 interval"
      timerComponents.push(
        <span className={styles.scc__vbs__countdown} key={interval}>
          <span className={styles.scc__vbs__countdown__number}>0</span>{" "}
          {interval}{" "}
        </span>
      );
    } else {
      // Otherwise, render the interval normally
      timerComponents.push(
        <span className={styles.scc__vbs__countdown} key={interval}>
          <span className={styles.scc__vbs__countdown__number}>
            {timeLeft[interval]}
          </span>{" "}
          {interval}{" "}
        </span>
      );
    }
  });

  return (
    <div className={styles.scc__vbs__container}>
      <img
        src={logo}
        alt="Rocky Railway VBS"
        className={styles.scc__rocky__railroad__logo}
      />
      <div className={styles.scc__vbs__countdown__text__container}>
        <h2 className={styles.scc__vbs__heading}>COUNTDOWN TO ALL ABOARD:</h2>
        <div className={styles.scc__vbs__countdown__container}>
          {timerComponents.length ? (
            timerComponents
          ) : (
            <span className={styles.scc__vbs__subheading}>
              Our Adventure Has Begun, But it is <em>Not Too Late</em> to Join
            </span>
          )}
        </div>
      </div>
      <div className={styles.scc__vbs__text__container}>
        <h1 className={styles.scc__vbs__heading}>
          {new Date().getFullYear()} VACATION BIBLE SCHOOL
        </h1>
        <p className={styles.scc__vbs__subheading}>
          Hop aboard for an unforgettable railway journey!
        </p>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://youtu.be/nCeYDu4OPiU?si=DPZkzWfsk291G0L0"
          className={styles.scc__vbs__link}
        >
          LEARN MORE
        </a>
        <button
          onClick={() => navigate("/vbs/signup")}
          className={styles.scc__vbs__button}
        >
          SIGN UP
        </button>
      </div>
    </div>
  );
}

import { useState } from "react";
import { Button, Container, Navbar, Stack } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import sccImgWhite from "../assets/images/sccImgWhite.png";
import styles from "../assets/stylesheets/NavbarHeader.module.css";
import Footer from "./Footer";

export default function NavbarHeader() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Navbar>
        <Container fluid className={styles.scc__nav__container}>
          <a href="/">
            <img
              src={sccImgWhite}
              alt="sccImgWhite"
              className={styles.scc__img}
            ></img>
          </a>
          <div className={styles.scc__nav__end}>
            <a
              tabIndex={0}
              target="_blank"
              rel="noreferrer"
              href="https://southcbgchurch.churchcenter.com/giving"
              className={styles.scc__give__button}
            >
              GIVE
            </a>
            <Button className={styles.scc__nav__button} onClick={handleShow}>
              <i className="fa-solid fa-grip-lines"></i>
            </Button>
          </div>
        </Container>
        <div
          className={`${styles.scc__side__nav} ${
            show ? `${styles.scc__side__nav__show}` : ""
          }`}
        >
          <Button className={styles.scc__nav__x} onClick={handleClose}>
            <i className="fa-solid fa-x"></i>
          </Button>
          <a
            href="/new"
            className={`${styles.scc__nav__new__hidden} ${
              show ? `${styles.scc__nav__new__show}` : ""
            }`}
          >
            I'M NEW
          </a>
          <a
            href="/about"
            className={`${styles.scc__nav__link} ${
              styles.scc__nav__link__hidden
            } ${show ? `${styles.scc__nav__link__show}` : ""}`}
          >
            ABOUT
          </a>
          <a
            href="/pastors"
            className={`${styles.scc__nav__link} ${
              styles.scc__nav__link__hidden
            } ${show ? `${styles.scc__nav__link__show}` : ""}`}
          >
            MEET OUR PASTORS
          </a>
          <a
            href="/connect"
            className={`${styles.scc__nav__link} ${
              styles.scc__nav__link__hidden
            } ${show ? `${styles.scc__nav__link__show}` : ""}`}
          >
            CONNECT
          </a>
          <a
            href="/events"
            className={`${styles.scc__nav__link} ${
              styles.scc__nav__link__hidden
            } ${show ? `${styles.scc__nav__link__show}` : ""}`}
          >
            EVENTS
          </a>
          <a
            href="/vbs"
            className={`${styles.scc__nav__link} ${
              styles.scc__nav__link__hidden
            } ${show ? `${styles.scc__nav__link__show}` : ""}`}
          >
            VBS
          </a>
          <a
            href="/"
            className={`${styles.scc__nav__link} ${
              styles.scc__nav__link__hidden
            } ${show ? `${styles.scc__nav__link__show}` : ""}`}
          >
            <i className="fa-solid fa-house"></i>
          </a>
        </div>
        <Stack>
          <Outlet />
        </Stack>
        <Footer />
      </Navbar>
    </>
  );
}

import sccUpcomingEvents from "../assets/images/sccUpcomingEvents.jpg";
import styles from "../assets/stylesheets/Events.module.css";

export default function Events() {
  return (
    <div>
      <img
        className={styles.scc__events__img}
        src={sccUpcomingEvents}
        alt=""
      ></img>
      <div className={styles.scc__embed__social__container}>
        <script src="https://embedsocial.com/js/iframe.js"></script>
        <iframe
          title="Instagram Feed"
          className={styles.scc__embed__social}
          src="https://embedsocial.com/api/pro_hashtag/187410b2a00ea5281209c9e5e317cdc2abb61ab5"
        ></iframe>
        <script>iFrameResize();</script>
      </div>
    </div>
  );
}

import styles from "../assets/stylesheets/Footer.module.css";

export default function Footer() {
  return (
    <div className={styles.scc__footer}>
      <p className={styles.scc__footer__title}>SOUTH CANONSBURG CHURCH</p>
      <a className={styles.scc__footer__link} href="tel:724-745-7438">
        724-745-7438
      </a>
      <br />
      <a
        className={styles.scc__footer__link}
        href="mailto:southlandministries01@gmail.com"
      >
        southlandministries01@gmail.com
      </a>
    </div>
  );
}

import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./components/About";
import Connect from "./components/Connect";
import Events from "./components/Events";
import Home from "./components/Home";
import NavbarHeader from "./components/NavbarHeader";
import NewPersonForm from "./components/NewPersonForm";
import Pastors from "./components/Pastors";
import ThankYou from "./components/ThankYou";
import PoweredUp from "./components/PoweredUp";
import Error from "./components/Error";
import Vbs from "./components/Vbs";
import VbsSignup from "./components/VbsSignup";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<NavbarHeader />}>
            <Route path="/" element={<Home />} />
            <Route path="/new" element={<NewPersonForm />} />
            <Route path="/thank-you" element={<ThankYou />} />
            <Route path="/about" element={<About />} />
            <Route path="/pastors" element={<Pastors />} />
            <Route path="/connect/powered-up" element={<PoweredUp />} />
            <Route path="/connect" element={<Connect />} />
            <Route path="/events" element={<Events />} />
            <Route path="/vbs/signup" element={<VbsSignup />} />
            <Route path="/vbs" element={<Vbs />} />
            <Route path="*" element={<Error />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

import sccHeadPhoto from "../assets/images/sccWebsiteHeader.jpg";
import styles from "../assets/stylesheets/Home.module.css";

export default function Home() {
  return (
    <div className={styles.scc__home__container}>
      <img className={styles.scc__head__img} alt="" src={sccHeadPhoto}></img>
      <div className={styles.scc__text__container}>
        <p className={styles.scc__text__heading}>
          <strong>WE'RE FAMILY, YOU BELONG</strong>
        </p>
        <p>
          We are located at the{" "}
          <span className={styles.scc__text__span}>
            <i className="fa-solid fa-heart"></i>
          </span>{" "}
          of Canonsburg
        </p>
        <p>
          <strong>Location:</strong>
          <a
            className={styles.scc__address}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.google.com/maps/dir//12+S+Central+Ave,+Canonsburg,+PA+15317/@40.2571538,-80.1889114,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8834548015ba0141:0xdec2009f2b154b3b!2m2!1d-80.1863365!2d40.2571497?entry=ttu"
          >
            12 South Central Avenue, Canonsburg, PA 15317
          </a>
        </p>
        <p>
          <strong>Sunday Service:</strong> 10am
        </p>
      </div>
      <div className={styles.scc__socials__container}>
        <a
          className={styles.scc__socials}
          href="https://www.youtube.com/channel/UCRbhT6hI6cMsdEAo4Bu0cug"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-brands fa-square-youtube"></i>
        </a>
        <a
          className={styles.scc__socials}
          href="https://www.facebook.com/southcanonsburgchurch/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-brands fa-square-facebook"></i>
        </a>
        <a
          className={styles.scc__socials}
          href="https://www.instagram.com/southcanonsburgchurch/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-brands fa-square-instagram"></i>
        </a>
      </div>
      <div className={styles.scc__video__container}>
        <p>
          <strong>CHECK OUT THIS WEEK'S SERMON:</strong>
        </p>
        <iframe
          title="Latest Sermon"
          className={styles.scc__latest__vid}
          src="https://www.youtube.com/embed/+lastest?list=PLnntDSL8mD5Ws2dEgCP59-GoqugTcyvVk"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}
